/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-21 19:16:42
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-22 09:41:11
 */
class HandleData {
  constructor (payload) {
    this.payload = Object.assign({}, payload)
  }

  admin (add) {
    const newPayload = {
      name: this.payload.name,
      username: this.payload.username,
      role_id: this.payload.role_id
    }
    if (!add) {
      newPayload.id = this.payload.id
    }
    return newPayload
  }

  operate (add) {
    const newPayload = {
      name: this.payload.name,
      username: this.payload.username,
      role_id: this.payload.role_id,
      agent_id: this.payload.agent_id
    }
    if (!add) {
      newPayload.id = this.payload.id
    }
    return newPayload
  }

  app (add) {
    const newPayload = {
      name: this.payload.name,
      username: this.payload.username,
      role_id: this.payload.role_id,
      agent_id: this.payload.agent_id,
      custom_id: this.payload.custom_id
    }
    if (!add) {
      newPayload.id = this.payload.id
    }
    return newPayload
  }

  updateStatus () {
    const newPayload = {
      id: this.payload.id
    }
    if (this.payload.status === 1) {
      newPayload.status = 2
    } else {
      newPayload.status = 1
    }
    return newPayload
  }
}

export default HandleData
