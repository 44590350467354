/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-20 10:11:47
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-26 02:00:22
 */
import { wVerifyEmail } from '@/plugins/verifier'
import HandleData from '@/service/User'

export default {
  name: 'UserAdd',
  data () {
    return {
      WUserAddShow: false,
      form: {
        name: '',
        username: '',
        role_id: ''
      },
      media_platform_list: [],
      rules: {
        name: [{
          validator (rule, val, res) {
            if (val === '') {
              res('请填写用户名称')
            }
            if (val.length > 50) {
              res('用户名称不超过50个字')
            }
            res()
          },
          trigger: 'blur',
          required: true
        }],
        username: [{
          validator (rule, val, res) {
            if (val === '') {
              res('请填写邮箱')
            } else if (!wVerifyEmail(val)) {
              res('请填写正确的邮箱')
            }
            res()
          },
          trigger: 'blur',
          required: true
        }],
        role_id: [{
          message: '请选择角色',
          trigger: 'blur',
          required: true
        }]
      }
    }
  },
  methods: {
    show () {
      this.WUserAddShow = true
    },
    hide () {
      this.WUserAddShow = false
    },
    cancel () {
      this.hide()
    },
    submit (add) {
      this.$refs.form.validate(v => {
        if (v) {
          if (add) {
            this.addSubmit()
          } else {
            this.editSubmit()
          }
        } else {
          this.$refs.submitButton.updatedLoading(false)
        }
      })
    },
    async addSubmit () {
      // 判断角色 2.平台运营 3.客户
      let payload = {}
      switch (this.form.role_id) {
        case 2:
          payload = new HandleData(this.form).operate(true)
          break;
        case 3:
          payload = new HandleData(this.form).app(true)
          break
        default:
          break;
      }
      const data = await this.$wPost('/operate/user/add.do', payload)
      console.log(data)
      if (data === true) {
        this.$wToast.success('新建成功')
        this.hide()
        this.$emit('add')
      }
      this.$refs.submitButton.updatedLoading(false)
    },
    async editSubmit () {
      let payload = {}
      switch (this.form.role_id) {
        case 2:
          payload = new HandleData(this.form).operate()
          break;
        case 3:
          payload = new HandleData(this.form).app()
          break
        default:
          break;
      }
      const data = await this.$wPost('/operate/user/update.do', payload)
      console.log(data)
      if (data === true) {
        this.$wToast.success('编辑成功')
        this.hide()
        this.$emit('add')
      }
      this.$refs.submitButton.updatedLoading(false)
    },
    isAdd () {
      if (this.type === 'add') {
        return true
      } else {
        return false
      }
    },
    close () {
      this.$refs.form.clearValidate()
      this.updatedForm({
        name: '',
        username: '',
        role_id: ''
      })
    },
    async updatedForm (payload) {
      for (const props in payload) {
        await this.$set(this.form, props, payload[props])
      }
    }
  },
  props: {
    type: {
      type: String,
      default: 'add'
    },
    roleList: {
      type: Array,
      default: () => []
    },
    customList: {
      type: Array,
      default: () => []
    }
  }
}
