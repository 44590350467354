/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-20 10:11:41
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-20 10:13:06
 */
import UserAdd from './UserAdd.vue'
export default UserAdd
