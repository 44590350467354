/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-20 09:40:00
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-20 09:41:31
 */
import UserTable from './UserTable.vue'
export default UserTable
