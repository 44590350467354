/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-20 09:25:47
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-20 09:26:52
 */
import User from './User.vue'
export default User
